var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getLoanData.loading)?_c('sm-loader'):_vm._e(),_c('div',{staticClass:"border border-solid border-blue-200"},[_c('datatable',{ref:"table",attrs:{"data":_vm.loans.data,"columns":_vm.loans.columns,"query":_vm.searchQuery,"loading":_vm.loans.loading,"on-click":_vm.click,"selectable":true,"exportable":true,"ajax":true,"ajax-pagination":true,"url":`${this.$baseurl}/admin/personal/loans/tangerine/loans?source=tangerineLife`,"search-field":_vm.searchField},on:{"selection-update":_vm.selectionUpdate}})],1),_c('div',{staticClass:"fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"},[_c('div',{staticClass:"text-sm text-gray-700 mb-4"},[_vm._v(" "+_vm._s(_vm.selection.length)+" Loan"+_vm._s(_vm.selection.length !== 1 ? 's' : '')+" Selected! ")]),(_vm.getFormError(_vm.form))?[_c('div',{staticClass:"alert alert-red-soft mb-4"},[_c('span',{staticClass:"alert-icon"},[_vm._v("!")]),_c('span',[_vm._v(_vm._s(_vm.getFormError(_vm.form)))])])]:_vm._e(),_c('div',{staticClass:"mb-10 max-h-200px overflow-y-auto"},[_vm._l((_vm.loans.failures),function(failure,n){return [_c('div',{key:n,class:{
            'border-b border-blue-200 pb-1 mb-2':
              n < _vm.loans.failures.length - 1
          }},[_c('div',{staticClass:"flex items-center text-xs"},[_c('span',{staticClass:"mr-3"},[_vm._v("Loan ID: "+_vm._s(failure.loan.id))]),_c('span',[_vm._v("Reason: "+_vm._s(failure.reason))])])])]})],2),_c('form-group',{attrs:{"type":"select","options":_vm.lenderOptions,"left-icon":"business-outline","name":"lender","form":_vm.form,"loading":_vm.lenders.loading},model:{value:(_vm.form.data.lender.value),callback:function ($$v) {_vm.$set(_vm.form.data.lender, "value", $$v)},expression:"form.data.lender.value"}},[_vm._v(" Select Lender ")]),_c('form-group',{attrs:{"name":"tenure","type":"number","form":_vm.form,"autofocus":true},model:{value:(_vm.form.data.tenure.value),callback:function ($$v) {_vm.$set(_vm.form.data.tenure, "value", $$v)},expression:"form.data.tenure.value"}},[_vm._v(" "+_vm._s(_vm.form.data.tenure.value == 1 ? 'Month' : 'Months')+" ")]),_c('div',[_c('button',{staticClass:"btn btn-blue btn-sm mr-3",attrs:{"disabled":_vm.form.loading ||
            !_vm.selection.length ||
            !_vm.form.data.lender.value ||
            !_vm.form.data.tenure.value},on:{"click":function($event){$event.preventDefault();return _vm.sendToTangerineLife.apply(null, arguments)}}},[_c('ion-icon',{staticClass:"text-lg mr-2",attrs:{"name":"checkmark-done-outline"}}),(_vm.form.loading)?_c('span',[_vm._v("Marking")]):_c('span',[_vm._v("Send to TangerineLife")])],1),_c('button',{staticClass:"btn btn-gray btn-sm",attrs:{"disabled":_vm.form.loading || !_vm.selection.length},on:{"click":function($event){$event.preventDefault();return _vm.clear.apply(null, arguments)}}},[_c('ion-icon',{staticClass:"text-lg mr-2",attrs:{"name":"close-circle-outline"}}),_c('span',[_vm._v("Clear Selection")])],1)])],2),_c('modals-user',{ref:"viewModal",attrs:{"selected-user":_vm.selectedUser,"allow-delete":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }